import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vueMatomo from "vue-matomo";
import vueHotjar from "vue-hotjar";
import Toast, { POSITION, TYPE } from "vue-toastification";
import "vue-toastification/dist/index.css";

import "./assets/css/tailwind.css";

const app = createApp(App);

app.use(router);

app.use(vueHotjar, {
  id: "3283503",
  isProduction: true,
  snippetVersion: 6,
});

app.use(vueMatomo, {
  host: "https://datalatte.matomo.cloud",
  siteId: 1,
  trackerFileName: "matomo",
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  // disableCookies: false,
  // requireCookieConsent: false,
  // cookieDomain: "*.datalatte.com",
  enableHeartBeatTimer: false,

  // Set the heartbeat timer interval
  // Default: 15
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,

  // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
  // Default: undefined, example: '*.example.com'
  domains: undefined,
  preInitActions: [],
  trackSiteSearch: false,
  crossOrigin: undefined,
});

const toastOptions = {
  position: POSITION.BOTTOM_RIGHT,
  type: TYPE.ERROR,
  maxToasts: 5,
};
app.use(Toast, toastOptions);

app.mount("#app");

console.log(process.env.VUE_APP_VERSION);
